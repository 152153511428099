// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-diligencemonster-ts": () => import("./../../../src/pages/diligencemonster.ts" /* webpackChunkName: "component---src-pages-diligencemonster-ts" */),
  "component---src-pages-dispute-ts": () => import("./../../../src/pages/dispute.ts" /* webpackChunkName: "component---src-pages-dispute-ts" */),
  "component---src-pages-family-law-ts": () => import("./../../../src/pages/family-law.ts" /* webpackChunkName: "component---src-pages-family-law-ts" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-policysaurus-diversity-ts": () => import("./../../../src/pages/policysaurus/diversity.ts" /* webpackChunkName: "component---src-pages-policysaurus-diversity-ts" */),
  "component---src-pages-policysaurus-privacyandcybersecurity-ts": () => import("./../../../src/pages/policysaurus/privacyandcybersecurity.ts" /* webpackChunkName: "component---src-pages-policysaurus-privacyandcybersecurity-ts" */),
  "component---src-pages-policysaurus-sustainability-ts": () => import("./../../../src/pages/policysaurus/sustainability.ts" /* webpackChunkName: "component---src-pages-policysaurus-sustainability-ts" */),
  "component---src-pages-policysaurus-ts": () => import("./../../../src/pages/policysaurus.ts" /* webpackChunkName: "component---src-pages-policysaurus-ts" */),
  "component---src-pages-sitemap-ts": () => import("./../../../src/pages/sitemap.ts" /* webpackChunkName: "component---src-pages-sitemap-ts" */),
  "component---src-pages-team-ts": () => import("./../../../src/pages/team.ts" /* webpackChunkName: "component---src-pages-team-ts" */),
  "component---src-pages-testimonials-ts": () => import("./../../../src/pages/testimonials.ts" /* webpackChunkName: "component---src-pages-testimonials-ts" */),
  "component---src-pages-transactions-ts": () => import("./../../../src/pages/transactions.ts" /* webpackChunkName: "component---src-pages-transactions-ts" */),
  "component---src-pages-wills-ts": () => import("./../../../src/pages/wills.ts" /* webpackChunkName: "component---src-pages-wills-ts" */)
}

